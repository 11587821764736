import { Platform } from '@angular/cdk/platform';
import { ErrorHandler, Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private platform: Platform,
    private environmentService: EnvironmentService
  ) {}

  handleError(error: Error): void {
    if (this.platform.isBrowser) {
      throw error;
    }
    const errorJSON = {
      env: this.environmentService.isProduction ? 'production' : 'dev',
      level: 'error',
      name: error?.name ?? 'Error',
      message:
        (error?.message ?? 'unknown message') +
        ' - caught in GlobalErrorHandler',
      stack: error?.stack ?? 'No Stack Trace Available',
      service: 'dev-portal-ssr',
    };
    console.error(JSON.stringify(errorJSON));
  }
}
