import { Component } from '@angular/core';

@Component({
  selector: 'seg-home',
  template: `<iframe #docIframe src="https://www.sportsengine.com/integrations-api" base="_parent"></iframe>`,
  styles: [`
    :host {
      display: block;
      padding: 4px;
      height: 100%;
    }
    iframe {
      height: 100%;
      width: 100%;
      border: none;
    }`
  ],
})
export class HomeComponent {}
