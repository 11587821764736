<mat-toolbar color="primary">
  <a class="brand-icon" href="home" title="Home" data-cy="menu-home-logo">
    <img
      class="brand-icon-image"
      src="assets/se-logo-white-header.png"
      alt="Logo"
    />
  </a>
  <!-- <span>Developer Portal</span> -->
  <nav class="flex-nav">
    <a
      routerLinkActive="nav-selected"
      mat-button
      data-cy="menu-home"
      routerLink="/"
      title="Getting Started"
      >Getting Started</a
    >
    <a
      routerLinkActive="nav-selected"
      mat-button
      data-cy="menu-explorer"
      routerLink="/explorer"
      title="Explorer"
      >Explorer</a
    >
    <div class="flex-spacer"></div>
  </nav>
</mat-toolbar>
