import { Component, Injector, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Message } from '@se-public-api/api-interfaces';
import { Platform } from '@angular/cdk/platform';
import { catchError, Observable, throwError } from 'rxjs';
import { HOST } from './injection-tokens';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'seg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  browser: boolean;
  hello$: Observable<Message>;
  gitCommitLong$: Observable<string>;

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private platform: Platform,
    private translateService: TranslateService
  ) {
    this.browser = platform.isBrowser;
    translateService.setDefaultLang('en');
  }

  ngOnInit() {
    this.hello$ = this.http.get<Message>('https://api.sportsengine.com/hello');
    const gitCommitFile = this.browser
      ? '/assets/git-commit.txt'
      : `http://${this.injector.get(HOST)}/assets/git-commit.txt`;
    this.gitCommitLong$ = this.http
      .get(gitCommitFile, {
        responseType: 'text',
      })
      .pipe(
        catchError(() => {
          return throwError(
            () =>
              new Error(
                `Failed to retrieve the git commit file from url: ${gitCommitFile}`
              )
          );
        })
      );
  }
}
