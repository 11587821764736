<ng-template [ngIf]="canDisplayIframe">
  <iframe
    src="/assets/graphiql-interface.html"
    width="100%"
    frameborder="0"
    scrolling="no"
    allowtransparency="true"
    data-cy="graphiql-frame"
  ></iframe>
</ng-template>
