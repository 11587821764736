<seg-menu></seg-menu>
<div class="main-container">
  <router-outlet></router-outlet>

  <div class="hidden">
    <div data-cy="app-api-heartbeat">
      API Heartbeat: {{ hello$ | async | json }}
    </div>
    <div data-cy="app-render-source">
      Render Source: {{ browser ? 'Browser' : 'Server' }}
    </div>
    <div data-cy="app-commit-id">Commit Id: {{ gitCommitLong$ | async }}</div>
  </div>
</div>
