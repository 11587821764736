import { Platform } from '@angular/cdk/platform';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'seg-explorer',
  templateUrl: './explorer.component.html',
  styleUrls: ['./explorer.component.scss'],
})
export class ExplorerComponent implements OnInit {
  canDisplayIframe: boolean;

  constructor(private platform: Platform) {
    this.canDisplayIframe = false;
  }

  ngOnInit(): void {
    this.canDisplayIframe = this.platform.isBrowser;
  }
}
